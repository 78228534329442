import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import AccentBox from "components/accentBox";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import List from "components/list";
import { Link, graphql } from "gatsby";
import Card from "components/card";
import SmallBox from 'components/smallBox';
export const CustomHead = styled.h2(props => ({
  color: props.color,
  background: props.bg,
  '&:before': {
    counterIncrement: 'head',
    content: '""'
  },
  '&:after': {
    borderTop: `10px solid ${props.bg}`
  }
}));
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "brands"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/rank/brands.png"}) { ...eyecatchImg },
    apple: file(relativePath: { eq: "pcmaker/apple.png"}) { ...normalImg },
    lenovo: file(relativePath: { eq: "pcmaker/lenovo.png"}) { ...normalImg },
    dell: file(relativePath: { eq: "pcmaker/dell.png"}) { ...normalImg },
    mouse: file(relativePath: { eq: "pcmaker/mouse.png"}) { ...normalImg },
    fujitsu: file(relativePath: { eq: "pcmaker/fujitsu.png"}) { ...normalImg },
    hp: file(relativePath: { eq: "pcmaker/hp.png"}) { ...normalImg },
    nec: file(relativePath: { eq: "pcmaker/nec.png"}) { ...normalImg },
    dynabook: file(relativePath: { eq: "pcmaker/dynabook.png"}) { ...normalImg },
    microsoft: file(relativePath: { eq: "pcmaker/microsoft.png"}) { ...normalImg },
    vaio: file(relativePath: { eq: "pcmaker/vaio.png"}) { ...normalImg },
    asus: file(relativePath: { eq: "pcmaker/asus_brand.png"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  CustomHead,
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`100人に聞いて分かったカッコいいパソコンブランドとダサいブランド`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="コスパ最強なノートパソコン" mdxType="Image" />
    <p>{`持ち歩くものでその人のセンスが問われる事がある。服装然り、時計然り、そしてパソコンも然りである。`}</p>
    <p>{`見た目そのものも重要であるが、ロゴを見たときに印象が変わることもしばしばある。`}</p>
    <p>{`そこで、本ページではネット上で100人にカッコいいパソコンブランドとダサいパソコンブランドを最大３つまで上げてもらったのでその調査結果をランキング形式で報告する。`}</p>
    <p>{`なお得票数が5未満であったメーカーに関しては認知不十分としてランキングから除外する。パナソニック、オンキョー、フロンティア、ACERなどが上がっていた。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <CustomHead bg="red" mdxType="CustomHead">11位 LENOVO (７カッコいい、１９ダサい)</CustomHead>
    <Image {...props} name="lenovo" alt="lenovo" mdxType="Image" />
    <p>{`中国メーカーの格安ブランドというイメージがあるのだろうか、ダサいと答えた人が最多の19票であり、およそ2割の人がダサいと答えたことになる。`}</p>
    <p>{`LENOVOは`}<strong parentName="p"><em parentName="strong">{`世界最大シェアのメーカー`}</em></strong>{`であり、洗練されたフラッグシップモデルからIdeaPadと呼ばれる普及価格帯の商品まで幅広く展開するが、普及価格帯モデルが全体のイメージを押し下げる結果となった模様。`}</p>
    <p>{`ただ天板にファブリックカバーを採用したモデルや極薄ベゼルのものなど、`}<strong parentName="p"><em parentName="strong">{`実際製品はデザイン性も高いものが多いので、イメージ先行となった結果`}</em></strong>{`であり、７カッコいいからも一定の評価は得られている事がわかるだろう。`}</p>
    <SmallBox type="word" text="公式サイトへ" mdxType="SmallBox">
  <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=884764152" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=884764152" height="1" width="1" border="0" alt="" />Lenovo</a>
    </SmallBox>
    <CustomHead bg="#0D54B5" mdxType="CustomHead">10位 Dell (６カッコいい １３ダサい)</CustomHead>
    <Image {...props} name="dell" alt="dell" mdxType="Image" />
    <p>{`店舗で販売せずに、直接販売を手がけることでコストを抑える米国メーカーがデルであり、`}<strong parentName="p"><em parentName="strong">{`コスパの黒船`}</em></strong>{`という異名も持つ。`}</p>
    <p>{`あまりカラーバリエーションなどに力を入れているイメージもないため妥当な順位かとは思われる。`}</p>
    <p>{`デザインが気に入ったモデルが見つかればラッキーと思い、`}<strong parentName="p"><em parentName="strong">{`基本的にはコストパフォーマンスを重視して選ぶメーカー`}</em></strong>{`と言ってもよいだろう。`}</p>
    <SmallBox type="word" text="公式サイトへ" mdxType="SmallBox">
      <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=296568.10003522&type=3&subid=0" rel="nofollow noopener" target="_blank">デル</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=296568.10003522&type=3&subid=0" />
    </SmallBox>
    <CustomHead bg="#9fa0a0" mdxType="CustomHead">9位 東芝(Dynabook) (３カッコいい ７ダサい)</CustomHead>
    <Image {...props} name="dynabook" alt="dynabook" mdxType="Image" />
    <p>{`そもそも東芝は認知度が低いということに問題がありそうである。`}</p>
    <p>{`実際には今シャープと合弁してDynabookという会社で開発や運営がなされているいるが、ダサいと答えた人は一人を除いて東芝と答えていた。`}</p>
    <p>{`一方でカッコいいと書いた人は全員Dynabookと答えており、`}<strong parentName="p"><em parentName="strong">{`実際Dynabookのモバイルノートは完成度が非常に高くデザインも洗練されている`}</em></strong>{`。`}</p>
    <SmallBox type="word" text="公式サイトへ" mdxType="SmallBox">
      <a href="https://linksynergy.jrs5.com/fs-bin/click?id=xsv0FDnJs1M&offerid=233988.10000411&type=3&subid=0" target="_blank" rel="nofollow noopener">Dynabook</a><img border="0" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=233988.10000411&type=3&subid=0" alt="" />
    </SmallBox>
    <CustomHead bg="#f60000" mdxType="CustomHead">8位 富士通 (９カッコいい １８ダサい)</CustomHead>
    <Image {...props} name="fujitsu" alt="fujitsu" mdxType="Image" />
    <p>{`カッコいいと答えた数だけで言うと全体で４位であるが、多くの人がダサいと答えたため８位とした。`}</p>
    <p>{`おそらく`}<strong parentName="p"><em parentName="strong">{`Arrowsというダサいスマートフォンを販売し続けていることがパソコンにも悪影響を与えている`}</em></strong>{`ものと推察する。`}</p>
    <p>{`LIFEBOOKというブランドで販売しているが、`}<strong parentName="p"><em parentName="strong">{`デザイン自体は全体的に洗練されており`}</em></strong>{`、「え、このカッコいいパソコンは富士通なの？」と思われることもしばしばありそう。`}</p>
    <p>{`ギャップ受けしそうなメーカーというのが個人評価。`}</p>
    <SmallBox type="word" text="公式サイトへ" mdxType="SmallBox">
      <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=183092.10000156&type=3&subid=0" target="_blank" rel="nofollow noopener">富士通</a><img border="0" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=183092.10000156&type=3&subid=0" alt="" />
    </SmallBox>
    <CustomHead bg="#5fb9f8" mdxType="CustomHead">7位 NEC (７カッコいい １４ダサい)</CustomHead>
    <Image {...props} name="nec" alt="nec" mdxType="Image" />
    <p>{`NECはそれなりに洗練されたデザインのパソコンを出しているが、`}<strong parentName="p"><em parentName="strong">{`幕の内弁当のような無難な製品しか出さないというブランドイメージが定着`}</em></strong>{`しているせいか、ダサいと答えた人が、カッコいいと答えた人の２倍であった。`}</p>
    <p>{`確かになんとなく丸っこいパソコンを多く出しているイメージがあり、カッコいい、`}<strong parentName="p"><em parentName="strong">{`洗練されたというイメージよりは安心して使えるというブランドコンセプト`}</em></strong>{`のもと製品開発を行なっている気がしなくもない。`}</p>
    <SmallBox type="word" text="公式サイトへ" mdxType="SmallBox">
      <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=885175200&vc_url=http%3A%2F%2Fnec-lavie.jp%2Fshop%2F%3Fsc_cid%3Djp%3Aaffiliate%3Atgniag" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=885175200" height="1" width="0" border="0" alt="" />NEC</a>
    </SmallBox>
    <CustomHead bg="#f9f104" color="black" mdxType="CustomHead">6位 マウスコンピュータ (６カッコいい ９ダサい)</CustomHead>
    <Image {...props} name="mouse" alt="mouse" mdxType="Image" />
    <p>{`知名度がもっと低いと思っていたが全体で１４票と多くの票を獲得した。マウスコンピュータはBTO(受注組み立て生産)のメーカーとして突出した知名度を誇るメーカーである。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`学生からはカースト最下位のパソコンという評判も聞いたことがある`}</em></strong>{`ものの、カラーバリエーションの天板を用意するなど一定のデザインへのこだわりも感じられる。`}</p>
    <p>{`またマウスのクリエーター向けサブブランド`}<strong parentName="p"><em parentName="strong">{`DAIVはロゴにデジタル感や近未来感があり普通に良い`}</em></strong>{`。`}</p>
    <p>{`国内シェアは拡大基調であるが、チーズマークのねずみブランドが、ねずみにかじられたリンゴブランドに並ぶ日は来るのだろうか。`}</p>
    <SmallBox type="word" text="公式サイトへ" mdxType="SmallBox">
  <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886847755" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886847755" height="1" width="1" border="0" alt="" />マウスコンピュータ</a>
    </SmallBox>
    <CustomHead bg="#165a6c" mdxType="CustomHead">５位 ASUS (６カッコいい 5ダサい)</CustomHead>
    <Image {...props} name="asus" alt="asus" mdxType="Image" />
    <p>{`５位には台湾のパソコンメーカーASUS(エイスース)がランクイン。iPhone生産のTSMCやシャープを買収した鴻海をはじめ、世界のハイテク工場となっている`}<strong parentName="p"><em parentName="strong">{`台湾は、ハードウェアの製造能力が高い企業が多く、ASUSも例外ではない`}</em></strong>{`。`}</p>
    <p>{`テンキーをノートパッドに表示させたり、二画面ノートパソコンを発売したりなど常に新しい試みを行なっており、ゲーミングパソコンのROGシリーズも軽量ボディに高性能なグラフィックボードを搭載するなど技術力の高さが伺える。`}</p>
    <p>{`このあたりの`}<strong parentName="p"><em parentName="strong">{`先進性や技術力がブランド価値の向上につながっている`}</em></strong>{`ものと考える。`}</p>
    <SmallBox type="word" text="公式サイトへ" mdxType="SmallBox">
      <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=858354.11&type=3&subid=0" target="_blank" rel="nofollow noopener">ASUS</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=858354.11&type=3&subid=0" />
    </SmallBox>
    <CustomHead bg="#009ace" mdxType="CustomHead">４位 HP (１０カッコいい ３ダサい)</CustomHead>
    <Image {...props} name="hp" alt="hp" mdxType="Image" />
    <p>{`４位にはアメリカのシリコンバレーに本拠地を置くHP(ヒューレッドパッカード)がランクイン。`}</p>
    <p>{`DellやLenovoと同様世界シェアが高いメーカーであり、格別この２つのメーカーと比べてデザインの質が高いかと言われると疑問であるが、`}<strong parentName="p"><em parentName="strong">{`安易に安売りがされていないイメージがあり、ブランド構築力に優れる`}</em></strong>{`。`}</p>
    <p>{`本物の木材を本体に採用したモバイルノートなど、`}<strong parentName="p"><em parentName="strong">{`デザイン感度の高い人に刺さるパソコンもしばしば発売されている`}</em></strong>{`。`}</p>
    <SmallBox type="word" text="公式サイトへ" mdxType="SmallBox">
      <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=252926.507&type=3&subid=0" target="_blank" rel="nofollow noopener">HP</a><img border="0" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=252926.507&type=3&subid=0" alt="" />
    </SmallBox>
    <CustomHead bg="#7bb400" mdxType="CustomHead">３位 マイクロソフト (５カッコいい ０ダサい)</CustomHead>
    <Image {...props} name="microsoft" alt="microsoft" mdxType="Image" />
    <p>{`Microsoftはソフトとハードの両方を手がけて新しい使い方を定義するというコンセプトのもとSurafaceというブランドを立ち上げた。`}</p>
    <p>{`おしゃれで折りたためるアークマウスやスエードのような素材でできたキーボードなど`}<strong parentName="p"><em parentName="strong">{`随所にこだわりが見られるデザインの製品が多い`}</em></strong>{`。`}</p>
    <p>{`また、量販店では特設スペースを設けて、他社と比較しないような売り方をしていることも費用は掛かるもののブランド価値向上に一役買っている。`}</p>
    <p>{`一方で全体投票数が５票と`}<strong parentName="p"><em parentName="strong">{`知名度に関してはまだまだ`}</em></strong>{`のようである。`}</p>
    <SmallBox type="word" text="公式サイトへ" mdxType="SmallBox">
      <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=791390.9&type=3&subid=0" target="_blank" rel="nofollow noopener">Microsoft(Surface)</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=791390.9&type=3&subid=0" />
    </SmallBox>
    <CustomHead bg="black" mdxType="CustomHead">２位 VAIO （１７カッコいい ３ダサい）</CustomHead>
    <Image {...props} name="vaio" alt="vaio" mdxType="Image" />
    <p>{`ソニーから分離独立したメーカー。`}<strong parentName="p"><em parentName="strong">{`黒を基調とした高級感のあるカーボンボディを特徴で、Windows PCとしてはカッコいいと答えた人が最も多い`}</em></strong>{`結果となった。分離したとはいえソニーブランドは健在ということかもしれない。`}</p>
    <p>{`ビジネス用途が主であり、軽量化や堅牢さには力を入れていることもあり、経営層や高級取りのサラリーマンが使っているイメージが強い。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`価格はスペックの割に明らかに高く`}</em></strong>{`、安易に安売りされていないこともブランド力を保つ一因となっていることは間違いないだろう。`}</p>
    <SmallBox type="word" text="公式サイトへ" mdxType="SmallBox">
      <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=596216.7&type=3&subid=0&LSNSUBSITE=LSNSUBSITE" target="_blank" rel="nofollow noopener">VAIO</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=596216.7&type=3&subid=0" />
    </SmallBox>
    <CustomHead bg="#f0e5d2" color="black" mdxType="CustomHead">１位 Apple (４４カッコいい ０ダサい)</CustomHead>
    <Image {...props} name="apple" alt="apple" mdxType="Image" />
    <p>{`皆が予想していた通りで面白味にはかけるが、圧倒的多数でAppleが１位となった。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`アルミ削りだしの継ぎ目のないボディやファンレス設計などハードウェア本体が洗練されている`}</em></strong>{`ことはもちろん、都心の一等地に広々とした専門店を設けるなど、高級ブランド店にみられるような店舗戦略でマーケティングにも抜かりがない。`}</p>
    <p>{`市民権を得すぎたため、スタバでのMacをする人がドヤラーと言われて揶揄されることがあり、値段が高いのは難点だが、`}<strong parentName="p"><em parentName="strong">{`圧倒的なブランド力があることは周知の事実`}</em></strong>{`である。`}</p>
    <SmallBox type="word" text="公式サイトへ" mdxType="SmallBox">
      <a href="https://www.apple.com/jp/mac/" target="_blank" rel="nofollow noopener">Apple Store</a>
    </SmallBox>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      